import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", {
    attrs: {
      "after-close": _vm.afterModalClose,
      "title": "Edit Offsite Storage"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function() {
        return [_c("a-button", {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v("Cancel")]), _c("a-button", {
          attrs: {
            "loading": _vm.isLoading,
            "type": "primary"
          },
          on: {
            "click": _vm.onSave
          }
        }, [_vm._v(" Update & Print Barcodes ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visible,
      callback: function($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("text-input", {
    attrs: {
      "value": _vm.record.intakeNumber,
      "label": "Intake Number",
      "form-item": "",
      "disabled": true
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 18
    }
  }, [_c("text-input", {
    attrs: {
      "value": _vm.record.crop,
      "disabled": true,
      "label": "Crop",
      "form-item": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Warehouse",
      "disabled": true,
      "form-item": "",
      "value": _vm.warehouse
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "data-source": _vm.storeGrades,
      "source": "id",
      "source-label": "name",
      "form-item": "",
      "label": "Storage Grade"
    },
    on: {
      "change": _vm.onStorageGradeChange
    }
  })], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditOffsiteStorage",
  inject: ["resourceProps"],
  data() {
    const [storageProps, storageGradeProps] = this.resourceProps;
    const apiUrl2 = "#{VUE_APP_API_URL}#";
    return {
      visible: true,
      storageGradeProps,
      storageProps,
      apiUrl: apiUrl2,
      isLoading: false
    };
  },
  computed: {
    record() {
      return this.storageProps.crud.getEntity(this.$route.params.id) || {};
    },
    warehouse() {
      return this._.get(this.storageGrade, "[0].warehouse");
    },
    storeGrades() {
      return this._.get(this.storageGrade, "[0].storeGrades") || [];
    },
    storageGrade() {
      return this.storageGradeProps.crud.getList();
    }
  },
  async mounted() {
    const customUrl = `${this.apiUrl}/grower/offsite-storages/${this.$route.params.id}/storagegrade`;
    await this.storageGradeProps.crud.fetchList(customUrl);
  },
  methods: {
    afterModalClose() {
      this.$router.push(this.storageGradeProps.redirectRoute);
    },
    cancel() {
      this.visible = false;
    },
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.storageGradeProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    onStorageGradeChange(value) {
      this.storeValue("storageGradeId", value);
    },
    async onSave() {
      this.isLoading = true;
      const customUrl = `${this.apiUrl}/grower/offsite-storages/${this.$route.params.id}/storagegrade`;
      try {
        await this.storageGradeProps.crud.submitEntity("replace", customUrl);
      } finally {
        this.isLoading = false;
        this.visible = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditOffsiteStorage = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "writeoff-bunker-container"
  }, [_c("resource", {
    attrs: {
      "name": ["grower.offsite-storages", "grower.offsite-storages.storagegrade"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/watties-grower/offsite-storage", "/watties-grower/offsite-storage"]
    }
  }, [_c("edit-offsite-storage")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditOffsiteStorage
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
